import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from "react";

import { Checkbox } from "antd";
import { update } from "lodash";
import moment from "moment";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../Common/UIComponents/StyledButton";
import EnvConfirmModal from "../Analytics/Components/EnvConfirmModal";
import CreateSamplingEventModal from "../components/CreateSamplingEventModal";
import CustomCalendar from "../components/CustomCalendar";
import EventsFilter from "../components/EventsFilter";
import ExportModal from "../components/reportmodals/ExportModal";
import PrintLabelModal from "../components/reportmodals/PrintLabelModal";
import EventGrid from "./EventGrid";

import { hasFeature } from "../../../utils/common";
import { getWeekDays } from "../utils";

import { deleteEnvSchedule, getEnvSchedule, patchEnvSchedule } from "../../../actions/envCalendar"; // eslint-disable-line

import { ReactComponent as EventExportIcon } from "../../../assets/images/environment/EventExportIcon.svg";
import { ReactComponent as PrintLabelsIcon } from "../../../assets/images/environment/PrintLabelsIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/onboarding/Plus.svg";

import "./Calendar.css";

const tabList = [
  "Select Locations",
  "Add/Edit Tests",
  "Select Frequency",
  "Summary",
];

const editTabList = {
  report: [
    "Add/Edit Tests",
    "Summary",
  ],
  calendar: tabList,
};

export default function Calendar() {
  const [scheduleData, setScheduleData] = useState({});
  const [openCreateSamplingEventModal, setOpenCreateSamplingEventModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([moment().format("MM/DD/YYYY")]);
  const [tabSequence, setTabSequence] = useState(tabList);
  const [selectedTab, setSelectedTab] = useState(tabSequence.length ? tabList[0] : null);
  const [markedDates, setMarkedDates] = useState([]);
  const [isEditingConfirm, setIsEditingConfirm] = useState(false);
  const [isDeletingConfirm, setIsDeletingConfirm] = useState(false);
  const [isPrintLabelModalVisible, setIsPrintLabelModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [specFilterValue, setSpecFilterValue] = useState("");
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const defaultDateRange = useMemo(() => {
    const dateToObj = new Date();
    const dateToString = moment(dateToObj).format("YYYY-MM-DD");

    const dateFromObj = new Date();
    dateFromObj.setDate(dateToObj.getDate() - 6);
    const dateFromString = moment(dateFromObj).format("YYYY-MM-DD");
    return {
      dateFromObj, dateFromString, dateToObj, dateToString,
    };
  }, []); // default for one week
  // Create sample event states
  const [newSamplingEventData, setNewSamplingEventData] = useState({
    samples: [],
    schedule: {
      dates: [],
      frequency: "",
    },
    event_name: "",
    action: "calendar-add",
    scheduled_from: "calendar-add",
    po: "",
  });

  // Delete event
  const [deleteEventData, setDeleteEventData] = useState({
    event_id: "",
    delete_recurring: true,
    submission_id: "",
    event_frequency: "",
  });

  // Edit event
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [editRecurring, setEditRecurring] = useState(true);
  const [editSchedulingPayload, setEditSchedulingPayload] = useState(null);
  const [hasEditEventFrequencyChanged, setHasEditEventFrequencyChanged] = useState(false);

  // Print from event card
  const [eventDataToPrintLabel, setEventDataToPrintLabel] = useState(null);

  // Loading states
  const [calendarLoading, setCalendarLoading] = useState(true);

  // Refs
  const controller = useRef();
  const flatpickrRef = useRef();

  const options = {
    plugins: [],
    inline: true,
    dateFormat: "m/d/Y",
    mode: "single",
    // minDate: moment(new Date(2020, 0)).startOf("year").format("MM/DD/YYYY"),
    // maxDate: moment(new Date(2025, 0)).endOf("year").format("MM/DD/YYYY"),
  };

  /**
   * Calls envschedule/ api to GET scheduling data
   * @param {Date} start start date
   * @param {Date} end end date
   */
  const apiGetEnvSchedule = useCallback(async (start, end) => {
    setCalendarLoading(true);
    const spec_filters = specFilterValue;

    const abortController = new AbortController();
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = abortController;
    const startString = moment(start, "MM/DD/YYYY").format("YYYY-MM-DD");
    const endString = moment(end, "MM/DD/YYYY").format("YYYY-MM-DD");
    const result = await getEnvSchedule(startString, endString, spec_filters, controller.current.signal);

    if (result && result.success) {
      const data = result.result;
      if (Array.isArray(data) && data.length === 0) {
        setScheduleData({});
        setMarkedDates([]);
      } else {
        setScheduleData(data);
        const marked_dates = (data.scheduled_submission_dates ?? [])
          .map((d) => moment(d).format("MM/DD/YYYY"));
        setMarkedDates(marked_dates);
      }
    } else if (!result.canceled) {
      toast.error(result.message);
    }
    setCalendarLoading(result.canceled); // if API is aborted, then keep the loading to true
  }, [specFilterValue]);

  /**
   * Calls DELETE envschedule/ to delete event
   */
  const apiDeleteEnvSchedule = async () => {
    const {
      event_id, submission_id, delete_recurring, event_frequency,
    } = deleteEventData;
    const isRecurring = event_frequency !== "one-time" && delete_recurring;
    const response = await deleteEnvSchedule(event_id, isRecurring, submission_id, "calendar-delete");
    if (response.success) {
      toast.success("Event deleted");
    } else {
      toast.error(response.message);
    }
  };

  /**
   * Fetch data from envschedule/ api
   */
  const fetchEnvScheduleData = useCallback(async () => {
    const { startDate, endDate } = getWeekDays(selectedDates[0]);
    await apiGetEnvSchedule(startDate, endDate);
  }, [apiGetEnvSchedule, selectedDates]);

  /**
   * Fetches scheduling data when -
   * 1. Initial load
   * 2. Change in selected date
   * 3. Spec filter changes
   */
  useEffect(() => {
    fetchEnvScheduleData();
  }, [selectedDates, specFilterValue, fetchEnvScheduleData]);

  /**
   * handler for date change using calendar
   * @param {Date[]} param Array for selected dates
   */
  const handleCalendarDateChange = ([d]) => {
    setSelectedDates([d]);
  };

  /**
   * Handle visibility of create sampling event modal
   * @param {Boolean} open true/false
   */
  const handleCreateSamplingEventModal = (open) => {
    setOpenCreateSamplingEventModal(open);
  };

  /**
   * Reset state to initial when create sampling modal closes
   */
  const resetCreateSamplingData = () => {
    setEditingData(null);
    setIsEditing(false);
    setTabSequence(tabList);
    setSelectedTab(tabList[0]);
    setNewSamplingEventData({
      samples: [],
      schedule: {
        dates: [],
        frequency: "",
      },
      event_name: "",
      action: "calendar-add",
      scheduled_from: "calendar-add",
      po: "",
    });
    setEditRecurring(true);
    setEditSchedulingPayload(null);
    setDeleteEventData({
      event_id: "",
      delete_recurring: true,
      submission_id: "",
      event_frequency: "",
    });
  };

  /**
   * Handles delete event
   */
  const handleDeleteEvent = async () => {
    setIsConfirmLoading(true);
    await apiDeleteEnvSchedule();
    setDeleteEventData({
      event_id: "",
      delete_recurring: true,
      submission_id: "",
      event_frequency: "",
    });
    setIsDeletingConfirm(false);
    setIsConfirmLoading(false);

    setCalendarLoading(true);
    await fetchEnvScheduleData();
    setCalendarLoading(false);
  };

  /**
   * Opens create sampling modal for editing scheduled status event
   * @param {*} schedulingData
   */
  const editEvent = (schedulingData) => {
    let scheduled_from = "calendar";
    if (schedulingData.scheduled_from && schedulingData.scheduled_from.includes("report")) {
      scheduled_from = "report";
    }
    // For submitted submission, don't show the "Select Frequency" tab
    const targetTabList = schedulingData.status === "-2" ? editTabList[scheduled_from] : editTabList[scheduled_from].filter((tab) => tab !== "Select Frequency");
    setTabSequence(targetTabList);
    setSelectedTab(targetTabList[0]);
    setEditingData(schedulingData);
    setIsEditing(true);
    setOpenCreateSamplingEventModal(true);
  };

  /**
   * Handles editing event
   */
  const handleEditSchedule = async () => {
    if (isEditing && editSchedulingPayload) {
      setIsConfirmLoading(true);
      const payload = {
        ...editSchedulingPayload,
        edit_recurring: editingData?.event_frequency !== "one-time" && editRecurring,
        action: "calendar-edit",
      };
      if (!payload.edit_recurring && editingData?.event_frequency !== "one-time") {
        update(payload, "schedule.dates", () => [
          moment(editingData.event_date).format("YYYY-MM-DD"),
        ]);
      }
      const response = await patchEnvSchedule(payload);
      if (response.success) {
        handleCreateSamplingEventModal(false);
        setIsEditing(false);
        setEditRecurring(true);
        setEditSchedulingPayload(null);
        setEditingData(null);
        setIsEditingConfirm(false);
        setTabSequence(tabList);
        setSelectedTab(tabList[0]);

        toast.success("Sampling Plan has been updated");

        setCalendarLoading(true);
        await fetchEnvScheduleData();
        setCalendarLoading(false);
      } else {
        setIsEditingConfirm(false);
        toast.error(response.message);
      }
      setIsConfirmLoading(false);
    }
  };

  /**
   * Resetting states when closing create sampling modal
   */
  const onCancelSamplingModal = async () => {
    handleCreateSamplingEventModal(false);
    resetCreateSamplingData();

    setCalendarLoading(true);
    await fetchEnvScheduleData(); // Refetch on modal close
    setCalendarLoading(false);
  };

  /**
   * Resetting states when closing edit sampling modal
   */
  const onConfirmEditOpen = () => {
    setIsEditingConfirm(true);
    // setTabSequence(tabList);
    // setSelectedTab(tabList[0]);
  };

  /**
   * handler for selecting print option from event card menu
   * @param {*} eventData Event data to print label
   */
  const onPrintLabel = (eventData) => {
    setEventDataToPrintLabel(eventData);
    setIsPrintLabelModalVisible(true);
  };

  return (
    <>
      <div className="Calendar__Container">
        <div className="Calendar__CalendarFiltersContainer">
          <Scrollbar id="Calendar__CalendarFiltersContainer__Scrollbar">
            <div className="Calendar__Calendar">
              <CustomCalendar
                value={selectedDates}
                ref={flatpickrRef}
                options={{ ...options }}
                onChange={handleCalendarDateChange}
                containerClassName="Calendar_FlatpickrCalendar"
                onDayCreate={(dObj, dStr, fp, dayElem) => {
                  const elemDate = moment(dayElem.getAttribute("aria-label"), "MMMM DD, YYYY").format("MM/DD/YYYY"); // eslint-disable-line
                  if (markedDates.includes(elemDate)) {
                    dayElem.innerHTML += "<span class='Calendar__MarkDay'></span>";
                  }
                }}
              />
            </div>
            <div className="Calendar__CreateButtonPanel">
              <StyledButton
                htmlType="button"
                size="large"
                className="Calendar__CreateButton"
                onClick={() => handleCreateSamplingEventModal(true)}
              >
                <PlusIcon />
                Create Sampling Event
              </StyledButton>
            </div>
            <div className="Calendar__EventFilters">
              <EventsFilter
                specFilterValue={specFilterValue}
                setSpecFilterValue={setSpecFilterValue}
              />
            </div>
          </Scrollbar>
        </div>
        <div className="Calendar__EventCalendar">
          <div className="Calendar__EventCalendar__Header">
            <span className="Calendar__EventCalendar__HeaderTitle">Week</span>
            <div className="Calendar__EventCalendar__ButtonContainer">
              <StyledButton onClick={() => setIsExportModalVisible(true)} htmlType="button" type="default" className="Calendar__EventCalendar__Button EventCalendar__ExportButton">
                Export
                <EventExportIcon />
              </StyledButton>
              {hasFeature("printer") && (
                <StyledButton onClick={() => setIsPrintLabelModalVisible(true)} htmlType="button" type="primary" className="Calendar__EventCalendar__Button">
                  <PrintLabelsIcon />
                  Print Labels
                </StyledButton>
              )}
            </div>
          </div>
          <EventGrid
            selectedDate={selectedDates[0]}
            scheduleData={scheduleData}
            setDeleteEventData={setDeleteEventData}
            setIsDeletingConfirm={setIsDeletingConfirm}
            afterMarkingSample={async () => {
              setCalendarLoading(true);
              await fetchEnvScheduleData(); // Refetch on modal close
              setCalendarLoading(false);
            }}
            onEdit={editEvent}
            onPrintLabel={onPrintLabel}
            calendarLoading={calendarLoading}
          />
        </div>
      </div>
      {openCreateSamplingEventModal && (
        <CreateSamplingEventModal
          open={openCreateSamplingEventModal}
          onCancel={onCancelSamplingModal}
          onConfirmEdit={onConfirmEditOpen}
          modalTitle={isEditing ? "Edit Sampling Plan" : "Create Sampling Event"}
          tabs={tabSequence}
          selectedTab={selectedTab}
          onTabChange={(tab) => setSelectedTab(tab)}
          disableSwitchFromTab
          newSamplingEventData={newSamplingEventData}
          setNewSamplingEventData={setNewSamplingEventData}
          editingData={editingData}
          isEditing={isEditing}
          setEditSchedulingPayload={setEditSchedulingPayload}
          setHasEditEventFrequencyChanged={setHasEditEventFrequencyChanged}
        />
      )}
      {isEditingConfirm && (
        <EnvConfirmModal
          width="520px"
          headerText="Edit Event"
          bodyText="Are you sure you want to proceed?" // (KEPT FOR FUTURE NEED): You're about to change the date of a recurring event. This will affect all future occurrences.
          buttonText={["Cancel", "Confirm"]}
          renderExtraContent={(!hasEditEventFrequencyChanged && editingData?.event_frequency !== "one-time") && (
            <Checkbox
              onChange={(e) => setEditRecurring(e.target.checked)}
              defaultChecked
            >
              Edit recurring event?
            </Checkbox>
          )}
          buttonFunctions={[
            () => {
              setIsEditingConfirm(false);
              // resetCreateSamplingData();
            },
            () => handleEditSchedule(),
          ]}
          loading={isConfirmLoading}
        />
      )}
      {isDeletingConfirm && (
        <EnvConfirmModal
          width="520px"
          headerText="Delete Event"
          bodyText="Are you sure you want to proceed?" // (KEPT FOR FUTURE USE): This action will remove all future sampling events for this location.
          buttonText={["Cancel", "Delete"]}
          renderExtraContent={deleteEventData?.event_frequency !== "one-time" && (
            <Checkbox
              onChange={(e) => {
                setDeleteEventData((state) => ({ ...state, delete_recurring: e.target.checked }));
              }}
              defaultChecked
            >
              Delete recurring event?
            </Checkbox>
          )}
          buttonFunctions={[
            () => {
              setIsDeletingConfirm(false);
              resetCreateSamplingData();
            },
            () => handleDeleteEvent(),
          ]}
          loading={isConfirmLoading}
        />
      )}
      {isPrintLabelModalVisible && (
        <PrintLabelModal
          defaultDateRange={defaultDateRange}
          eventData={eventDataToPrintLabel}
          onClose={() => {
            setIsPrintLabelModalVisible(false);
            setEventDataToPrintLabel(null);
          }}
          allowClear={false}
        />
      )}
      {isExportModalVisible && (
        <ExportModal
          defaultDateRange={defaultDateRange}
          onClose={() => setIsExportModalVisible(false)}
          allowClear={false}
        />
      )}
    </>
  );
}
