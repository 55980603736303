export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH;
export const AES_128_BIT_KEY = process.env.REACT_APP_AES_128_BIT_KEY;
export const ESV_DETAILS = process.env.REACT_APP_ESV_DETAILS;

export const ESV_PUB_BUCKET = process.env.REACT_APP_S3_ESV_PUB_BUCKET;
export const ESV_PRIV_BUCKET = process.env.REACT_APP_S3_ESV_PRIV_BUCKET;
export const AWS_BASE_PATH = process.env.REACT_APP_S3_BASE_PATH;
export const AWS_REGION = "us-east-2";

export const ZebraPinterApiKey = process.env.REACT_APP_ZEBRA_PRINTER_API_KEY;
export const ZebraPinterTenant = process.env.REACT_APP_ZEBRA_PRINTER_TENANT;
